import React, { useCallback } from 'react';
import { push } from 'connected-react-router';
import { store } from '../../store';
import { put } from '../../services/api';
import Button from '../../common/Button';
import { OFF_WALL_PARTICIPANT } from '../../utils/user-util';

export default function ViewOnlyModeButton({ notesData, onClick, style }) {
  const _handleOnClick = useCallback(async () => {
    const { eventParticipantId } = notesData;
    await put(`/eventParticipant/${eventParticipantId}`, { role: OFF_WALL_PARTICIPANT });

    store.dispatch(push({ pathname: '/connect', state: notesData, search: window.location.search }));
  }, [notesData, onClick]);

  if (notesData && notesData.event.allowOffWallSelfElection === true) {
    return <Button type='secondary' text={'Off-Camera Mode'} onClick={_handleOnClick} containerStyle={style} />;
  }

  return null;
}
