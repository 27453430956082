import React from 'react';
import Button from '../../common/Button';
import FailureIcon from '../../assets/system-check-icons/icon-check-failed.svg';
import ViewOnlyModeButton from './ViewOnlyModeButton';

export default function FailureMessage({ message, onRetry, notesData }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#1E2A36',
          padding: '50px 30px',
          width: 360,
        }}
      >
        <img src={FailureIcon} alt={''} width={160} />
        <h3 style={{ color: '#D4373B', marginTop: 20 }}>System check detected an issue</h3>

        <p style={{ textAlign: 'center', fontSize: 14 }}>
          <>
            It appears that you are unable to complete the system check at this time.
            {notesData && notesData.event.allowOffWallSelfElection === true ? (
              <>
                <br />
                <br />
                You can click the "Get Help" button below for immediate assistance or you can continue as an off-camera attendee.
                <br />
                <br />
                You can also retry running the system check.
              </>
            ) : (
              <>
                <br />
                <br />
                Click the "Get Help" button below for immediate assistance or you can retry running the system check.
              </>
            )}
          </>
        </p>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <ViewOnlyModeButton notesData={notesData} style={{ marginTop: 20, marginRight: 10 }} />
          <Button type='secondary' text={'Retry'} onClick={onRetry} containerStyle={{ marginTop: 20 }} />
        </div>
      </div>
    </div>
  );
}
